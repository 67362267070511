import styled from "@emotion/styled";
import { ActionIcon, Button, Flex, Text } from "@mantine/core";
import axios from "axios";
import { useModal } from "components/Modal/ModalStackManager";
import { FileNameLength, FileSize, MaxFiles } from "constants/fileInfo";
import BaseInstance, { BASE_URL } from "instance/axios";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { GoUpload } from "react-icons/go";
import { IoCloseOutline, IoSearch } from "react-icons/io5";
import { FileBox } from "./FileBox";
import { FileDropZone } from "./FileDropZone";
import { FileDropZoneDescription } from "./FileDropZoneDescription";
import { FileDropZoneTitle } from "./FileDropZoneTitle";
import { FilePreviewForm } from "./FilePreviewForm";

const File = {
  DropZone: FileDropZone,
  DropZoneTitle: FileDropZoneTitle,
  DropZoneDescription: FileDropZoneDescription,
  Box: FileBox,
};

export interface FileProps {
  createdAt?: string;
  fileName: string;
  filePath: Blob;
  id: number;
  tempId?: number;
  modifiedAt?: string;
  ticketId?: number;
  size?: number;
  preview?: string;
}

export interface AddedFileProps {
  path?: Blob;
  lastModified?: number;
  lastModifiedDate?: Date;
  id?: number;
  tempId?: number;
  name: string;
  size: number;
  type: string;
  webkitRelativePath?: string;
  preview?: string;
}

interface FileModalFormProps {
  onSave: (files: AddedFileProps[]) => void;
  ticketId?: number;
}

export const FileModalForm = ({ onSave, ticketId }: FileModalFormProps) => {
  const { openModal, closeModal } = useModal();
  const [files, setFiles] = useState<FileProps[]>([]);
  const [addedFiles, setAddedFiles] = useState<AddedFileProps[]>([]);
  const [uploadFiles, setUploadFiles] = useState<any>("");
  const { getRootProps, getInputProps } = useDropzone({
    maxSize: FileSize,
    maxFiles: MaxFiles,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".bmp"],
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles: AddedFileProps[], fileRejections) => {
      // 업로드된 파일에 고유한 키값 추가 (tempId)
      acceptedFiles.forEach((file, idx) => {
        Object.assign(file, {
          tempId: ~~(Math.random() * 10000000),
          preview: URL.createObjectURL(file as any),
        });
      });
      setAddedFiles((prevAddedFiles) => [...prevAddedFiles, ...acceptedFiles]);
    },
    onError: (err) => {
      console.log(err, "err");
    },
  });

  const convertAddedFileToFileList = addedFiles.map((addedFile) => {
    return {
      fileName: addedFile.name,
      size: addedFile.size,
      type: addedFile.type,
      id: undefined, //addedFile.id, // 랜덤 데이터
      tempId: addedFile.tempId,
      filePath: addedFile.path,
      preview: addedFile.preview,
    };
  });

  const getAttachment = () => {
    BaseInstance.attachmentsGet(
      {},
      {
        params: {
          query: JSON.stringify({
            ticketId: ticketId,
          }),
        },
      }
    ).then((result: any) => {
      if (result.data.rows.length > 0) {
        setFiles(result.data.rows);
      }
    });
  };

  const disabledBtn = addedFiles.length === 0;

  const handleSave = () => {
    onSave(addedFiles);
  };

  useEffect(() => {
    getAttachment();
  }, []);

  function openImageInNewTab(url: string, fileName: string) {
    const newTab = window.open() as Window;
    newTab.document.body.innerHTML = `<img src="${url}" alt="${fileName}" style="width: 100%; height: 100%; object-fit: contain;">`;
    // 이미지를 클릭하면 다운로드 시작
    newTab.document.body.onclick = () => {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      newTab.document.body.appendChild(link);
      link.click();
      newTab.document.body.removeChild(link);
    };
  }

  const handleClickFile = (file: any) => {
    // 파일 다운로드 API
    axios
      .get(`${BASE_URL}/ticket/attachment/${file.id}`, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        setUploadFiles(response);
      });
  };

  const downloadFile = (data: Blob, fileName: string, mime: string) => {
    const blob = new Blob([data], { type: mime });
    const url = window.URL.createObjectURL(blob);

    if (mime === "application/pdf") {
      openModal(
        <FilePreviewForm file={blob} fileName={fileName} />,
        "",
        "파일 미리 보기"
      );
    } else {
      openImageInNewTab(url, fileName);
    }
  };

  useEffect(() => {
    if (uploadFiles) {
      downloadFile(
        uploadFiles.data,
        uploadFiles?.fileName as string,
        uploadFiles.headers?.["content-type"]
      );
    }
  }, [uploadFiles]);

  return (
    <FileContainer
      w="40rem"
      h="auto"
      mah="40rem"
      direction="column"
      gap="sm"
      style={{ overflowY: "auto" }}
    >
      <Flex direction="column" gap="sm">
        <File.DropZone>
          <DropZone {...getRootProps()}>
            <input {...getInputProps()} />
            <File.DropZoneTitle>
              <Flex gap="md" justify="center" align="center">
                <GoUpload color="#000000" size={15} />
                <Text color="black" fz="md">
                  파일을 마우스로 끌어 오거나 클릭하여 파일을 선택하세요.
                </Text>
              </Flex>
            </File.DropZoneTitle>
          </DropZone>
        </File.DropZone>
        <File.DropZoneDescription>
          <Text>
            최대 10개 파일 업로드 가능. 각 파일 당 10MB 이내 pdf, jpg, jpeg,
            png, bmp 확장자 파일만 업로드 가능합니다.
          </Text>
        </File.DropZoneDescription>
      </Flex>
      <Flex direction="column" gap="sm">
        {[...files, ...convertAddedFileToFileList].map((file, index) => {
          function handleDelete(arg0: {
            fileName: string;
            id: any;
            tempId: any;
          }): void {
            if (window.confirm("파일을 삭제하시겠습니까?")) {
              // 임시 업로드된 파일 데이터와 업로드된 파일 데이터의 삭제를 분기 처리한다.
              if (file.id) {
                setFiles((prevFiles) =>
                  prevFiles.filter((file, index) => index !== arg0.id)
                );
                BaseInstance.attachmentsAttachmentIdDelete({
                  attachmentId: arg0.id,
                }).then((result) => {
                  if (result.status === 200) {
                    alert("파일이 삭제되었습니다.");
                    closeModal({});
                  }
                });
              } else {
                setAddedFiles((prevAddedFiles) =>
                  prevAddedFiles.filter((file) => file.tempId !== arg0.tempId)
                );
              }
            }
          }

          function handlePreview(previewFile: any): void {
            if (previewFile.type === "application/pdf") {
              openModal(
                <FilePreviewForm
                  file={file.preview}
                  fileName={file.fileName}
                />,
                "",
                "파일 미리 보기"
              );
            } else {
              openImageInNewTab(previewFile.preview, previewFile.fileName);
            }
          }

          return (
            <Flex
              key={file.id ?? file.tempId}
              justify="space-between"
              align="center"
              style={{ border: "2px solid black" }}
            >
              <Flex align="center" justify="flex-start">
                <Text color="black" fz="md" p="md">
                  {file.fileName}
                  {index >= files.length && (
                    <Text span color="red" fz="xl">
                      &nbsp;*
                    </Text>
                  )}
                </Text>
              </Flex>

              <Flex direction="row" gap="0.5rem" align="center">
                {file.size && file.size > FileSize && (
                  <Text fz="md" color="red" fw="700">
                    용량 초과
                  </Text>
                )}
                {file.fileName && file.fileName.length > FileNameLength && (
                  <Text fz="md" color="orange" fw="700">
                    파일명 길이제한
                  </Text>
                )}
                <ActionIcon
                  onClick={() =>
                    index >= files.length
                      ? handlePreview(file)
                      : handleClickFile(file)
                  }
                >
                  <IoSearch size={25} color="#000000" />
                </ActionIcon>
                <ActionIcon
                  onClick={() =>
                    // tempId는 임시 업로드된 파일의 고유한 키 값이다.
                    handleDelete({
                      fileName: file.fileName,
                      id: file.id,
                      tempId: file?.tempId,
                    })
                  }
                >
                  <IoCloseOutline size={25} color="#000000" />
                </ActionIcon>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      <Button disabled={disabledBtn} onClick={handleSave} mih="2rem">
        저장
      </Button>
    </FileContainer>
  );
};

const DropZone = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed #3b4758;
`;

const FileContainer = styled(Flex)`
  @media (max-width: 768px) {
    width: 100%;
  }
`;
