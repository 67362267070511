import styled from "@emotion/styled";
import {
  ActionIcon,
  Chip,
  Flex,
  MediaQuery,
  Pagination,
  Table,
  Text,
  Title,
} from "@mantine/core";
import { TicketsGet200ResponseRowsInner } from "@sizlcorp/mbk-api-document/dist/models";
import { IconReload } from "@tabler/icons-react";
import { useWhoamiQuery } from "api/useMasterQuery";
import SearchBox, { SearchProps } from "components/SearchBox";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTicketGetQuery } from "../api/useTicketQuery";

const SearchType = [
  { value: "", label: "전체" },
  { value: "assignedAdviserName", label: "담당 어드바이저" },
  // { value: "customerName", label: "고객명" },
  { value: "plateCode", label: "차량번호" },
  // { value: "carModel", label: "차량모델" },
  { value: "assignedTechnicianName", label: "담당 테크니션" },
  { value: "parkingLocation", label: "주차위치" },
];

const filteredSearchType = SearchType.filter((type) => type.value).map(
  (type) => type.value
);

const IN_PROGRESS_STATUS = [
  "STAGE_IN_PROGRESS", // "정비단계 진행중"
  "IN_PROGRESS_PENDING", // "정비단계 진행중 지연중"
  "STAGE_WAITING", // "정비단계 대기중"
  "WAITING_PENDING", // "정비단계 시작 전 지연중"
  "PROCESSING", // "상세 정비중"
  "PROCESS_PENDING", // "상세 정비 지연중"
  "DONE", // "정비 완료"
];

export const TicketTable = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getPageFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return Number(params.get("page")) || 1;
  };

  const [value, setValue] = useState<string>("all");

  const [currentPage, setCurrentPage] = useState<number>(getPageFromUrl());

  // 페이지 변경 함수
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    navigate({ search: `?page=${page}` }); // URL 업데이트
  };

  const [search, setSearch] = useState<SearchProps>({
    search: "",
    searchFields: [],
    pageSize: "10",
  });

  const { data: userInfo } = useWhoamiQuery();

  const { data, refetch } = useTicketGetQuery({
    activePage: currentPage,
    search: search.search,
    searchFields: search.searchFields.length
      ? search.searchFields
      : filteredSearchType,
    pageSize: search.pageSize,
    filter: value,
    userId: userInfo?.id,
  });

  // 정비전 지연 상태인 데이터 또한 표시한다. 하지만 정비대기 상태를 거치지 않은 정비전 지연 데이터는 표시하지 않는다.
  // 2024-12-18 rebi13
  const list = data?.data.rows?.filter((data) => {
    return (
      data?.stagesInfo &&
      data?.stagesInfo[0]?.logs?.filter((log) => log.stepStatus === "WAITING")
    )?.length;
  });

  const handleClickTicketId = (ticketId: string) => {
    // state로 이동 경로를 저장
    navigate(`/repair/${ticketId}`, {
      state: { from: `/qr?page=${currentPage}` },
    });
  };

  return (
    <ReceptionBox>
      <Flex gap="md" align="center" justify="center">
        <CarTitle order={2}>접수차량 목록</CarTitle>
        <ActionIcon onClick={() => refetch()}>
          <IconReload />
        </ActionIcon>
      </Flex>
      <SearchBox searchType={SearchType} setSearch={setSearch} />
      <Flex gap="md" justify="flex-end">
        <Chip.Group
          multiple={false}
          value={value}
          onChange={(e) => {
            setValue(e);
          }}
        >
          <Chip value="all" radius="xs" variant="filled" size="md">
            전체
          </Chip>
          <Chip value="unassigned" radius="xs" variant="filled" size="md">
            미배정 건
          </Chip>
          <Chip value="assigned" radius="xs" variant="filled" size="md">
            담당 건
          </Chip>
        </Chip.Group>
      </Flex>
      <Flex
        direction="column"
        align="flex-start"
        bg="white"
        w="100%"
        style={{ overflowY: "auto" }}
      >
        <Table withBorder highlightOnHover>
          <thead>
            <tr>
              <Th width={25}>
                <Text fz="lg" ta="center">
                  담당 어드바이저
                </Text>
              </Th>
              {/* <Th><Text ta="center">고객명</Text></Th> */}
              <Th width={25}>
                <Text fz="lg" ta="center">
                  차량번호
                </Text>
              </Th>
              {/* <Th><Text ta="center">모델명</Text></Th> */}
              <Th width={25}>
                <Text fz="lg" ta="center">
                  담당 테크니션
                </Text>
              </Th>
              <Th width={25}>
                <Text fz="lg" ta="center">
                  주차위치
                </Text>
              </Th>
              {/* <Th><Text ta="center">접수일시</Text></Th> */}
            </tr>
          </thead>
          <tbody>
            {list?.map((item: TicketsGet200ResponseRowsInner) => {
              return (
                <tr
                  key={item.id}
                  onClick={() => handleClickTicketId(String(item.id))}
                  style={{
                    cursor: "pointer",
                    backgroundColor: `${
                      IN_PROGRESS_STATUS.includes(item.stepStatus ?? "")
                        ? "#008DFC"
                        : "#FFFFFF"
                    }`,
                    transition: "background-color 0.3s ease", // 부드러운 배경색 전환을 위해 추가
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = `${
                      IN_PROGRESS_STATUS.includes(item.stepStatus ?? "")
                        ? "#7FC6FD"
                        : "#f0f0f0"
                    }`)
                  } // 호버 시 배경색 변경
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = `${
                      IN_PROGRESS_STATUS.includes(item.stepStatus ?? "")
                        ? "#008DFC"
                        : "#FFFFFF"
                    }`)
                  } // 호버 해제 시 원래 색상 복구
                >
                  <Td style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                    <Text
                      fz="xl"
                      p="sm"
                      ta="center"
                      style={{ wordBreak: "break-word", whiteSpace: "normal" }}
                    >
                      {item.assignedAdviser?.name}
                    </Text>
                  </Td>
                  {/* <Td width={15}>
                                        <Text fz="sm" p="sm" ta="center" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>{item.customerName}</Text>
                                    </Td> */}
                  <Td style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                    <Text
                      fz="xl"
                      p="sm"
                      ta="center"
                      style={{ wordBreak: "break-word", whiteSpace: "normal" }}
                    >
                      {item.plateCode}
                    </Text>
                  </Td>
                  {/* <Td width={20} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                        <Text fz="sm" p="sm" ta="center" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>{item.carModel}</Text>
                                    </Td> */}
                  <Td style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                    <Text
                      fz="xl"
                      p="sm"
                      ta="center"
                      style={{ wordBreak: "break-word", whiteSpace: "normal" }}
                    >
                      {item.assignedTechnician?.name}
                    </Text>
                  </Td>
                  <Td style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                    <Text
                      fz="xl"
                      p="sm"
                      ta="center"
                      style={{ wordBreak: "break-word", whiteSpace: "normal" }}
                    >
                      {item.parkingLocation ?? "-"}
                    </Text>
                  </Td>
                  {/* <Td width={20} style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                        <Text fz="sm" p="sm" ta="center" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>{dayjs(item.createdAt).format("YYYY-MM-DD A hh:mm ")}</Text>
                                    </Td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Flex>
      <Flex justify="center">
        <MediaQuery styles={{ display: "none" }} largerThan="sm">
          <Pagination
            onChange={handlePageChange}
            value={currentPage}
            total={data?.data?.totalPages ?? 0}
            size="sm"
            radius="sm"
          />
        </MediaQuery>
        <MediaQuery styles={{ display: "none" }} smallerThan="sm">
          <Pagination
            onChange={handlePageChange}
            value={currentPage}
            total={data?.data?.totalPages ?? 0}
            size="lg"
            radius="sm"
          />
        </MediaQuery>
      </Flex>
    </ReceptionBox>
  );
};

const ReceptionBox = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  border-radius: 50px;
  gap: 2rem;
  padding: 4rem;
  border-radius: 4rem;
  font-family: MBK CorpoS;

  @media (max-width: 768px) {
    padding: 0.5rem;
    gap: 1rem;
    border-radius: 1rem;
  }
`;

const CarTitle = styled(Title)`
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
  // .mantine-Text-root {
  //     font-size: 1.05rem;
  // }

  @media (max-width: 768px) {
    padding: 0.4rem 0 !important;

    // .mantine-Text-root {
    //     font-size: 0.75rem;
    // }
  }
`;

const Td = styled.td<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};

  @media (max-width: 768px) {
    padding: 0.2rem !important;
    // .mantine-Text-root {
    //     font-size: 0.75rem;
    //     padding : 1rem 0;
    // }
  }
`;
