import { TicketUserViewListResponse } from "@sizlcorp/mbk-api-document/dist/models";
import { AxiosResponse } from "axios";
import { TicketInstance, TicketUserViewInstance } from "instance/axios";
import { useQuery } from "react-query";

const TICKET_GET_QUERY_KEY = "/tickets";

const fetchTicketGet = (params?: any) => {

    if (params) {
        const queryData: any = {
            query: {
                stepStatus: ["WAITING", "TEMPORARY_STAGE_IN_PROGRESS", "PENDING", "STAGE_IN_PROGRESS", "STAGE_WAITING", "WAITING_PENDING", "IN_PROGRESS_PENDING", "PROCESSING", "PROCESS_PENDING", "DONE"],
                siteId: localStorage.getItem("siteId"),
            }
        };
        // 검색내용이 있을 때만 search를 보내야 한다.
        if (params.search) {
            queryData.search = params.search;
        }
        // 검색 필드가 있을 때만 searchFields 보내야 한다.
        if (params.searchFields) {
            queryData.searchFields = params.searchFields;
        }

        if (params.filter === "assigned") {
            queryData.query.assignedTechnicianId = { $eq: params.userId };
        }

        else if (params.filter === "unassigned") {
            queryData.query.assignedTechnicianId = { $eq: null };
        }

        return TicketInstance.ticketsGet(
            {
                ...queryData,
                page: params.activePage ?? 1,
                pageSize: params.pageSize ?? 10,
                populate: ["createUser", "stagesInfo", "assignedAdviser", "assignedTechnician"],
                sort: "-createdAt",
                query: [JSON.stringify(queryData.query)]
            },
        )
    } else return TicketInstance.ticketsGet({});
}

const fetchTicketUserViewGet = (params?: any) => {

    if (params) {
        const queryData: any = {
            query: {
                stepStatus: ["ACCEPT_PENDING", "WAITING", "TEMPORARY_STAGE_IN_PROGRESS", "PENDING", "STAGE_IN_PROGRESS", "STAGE_WAITING", "WAITING_PENDING", "IN_PROGRESS_PENDING", "PROCESSING", "PROCESS_PENDING", "DONE"],
                siteId: localStorage.getItem("siteId"),
            }
        };
        // 검색내용이 있을 때만 search를 보내야 한다.
        if (params.search) {
            queryData.search = params.search;
        }
        // 검색 필드가 있을 때만 searchFields 보내야 한다.
        if (params.searchFields) {
            queryData.searchFields = params.searchFields;
        }

        if (params.filter === "assigned") {
            queryData.query.assignedTechnicianId = { $eq: params.userId };
        }

        else if (params.filter === "unassigned") {
            queryData.query.assignedTechnicianId = { $eq: null };
        }

        return TicketUserViewInstance.ticketUserViewGet(
            {
                ...queryData,
                page: params.activePage ?? 1,
                pageSize: params.pageSize ?? 10,
                populate: ["createUser", "stagesInfo", "assignedAdviser", "assignedTechnician"],
                sort: "-createdAt",
                query: [JSON.stringify(queryData.query)]
            },
        )
    } else return TicketUserViewInstance.ticketUserViewGet({});
}

export const useTicketGetQuery = (params?: any) => {
    return useQuery<AxiosResponse<TicketUserViewListResponse, any[]>>({
        queryKey: [TICKET_GET_QUERY_KEY, params],
        queryFn: () => fetchTicketUserViewGet(params),
    });
}