import { Button, Flex, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  AuthSignupPost201ResponseRoleEnum,
  AuthSignupPostRequest,
} from "@sizlcorp/mbk-api-document/dist/models";
import { useUserMutation, useWhoamiQuery } from "api/useMasterQuery";
import { useModal } from "components/Modal/ModalStackManager";
import { useState } from "react";

export const roles: { label: string; value: string; isManagement?: boolean }[] =
  [
    { value: "TECHNICIAN", label: "테크니션" },
    { value: "ADVISER", label: "어드바이저" },
    { value: "ADMIN", label: "관리자" },
    { value: "DEALER", label: "딜러사", isManagement: true },
    { value: "MBK", label: "MBK", isManagement: true },
  ];

const EditForm = () => {
  const { closeModal } = useModal();
  const { data: userLoginData } = useWhoamiQuery();

  const { email, name, role, mobile, exposedMobile } = userLoginData || {};

  const formData = useForm<AuthSignupPostRequest>({
    initialValues: {
      siteId: Number(localStorage.getItem("siteId")),
      email: email || "",
      name: name || "",
      role: role as AuthSignupPost201ResponseRoleEnum,
      password: "",
      mobile: mobile || "",
      exposedMobile: exposedMobile || "",
    },
  });

  const [passwordCheck, setPasswordCheck] = useState<string>("");

  const { mutateAsync } = useUserMutation();

  const onSubmit = async () => {
    if (
      !(
        formData.values.email.length > 0 &&
        formData.values.name.length > 0 &&
        formData.values.password.length > 0
      ) ||
      formData.values.password !== passwordCheck
    ) {
      alert("필수 항목들을 입력해주세요.");
      return;
    }

    try {
      mutateAsync(
        {
          userId: userLoginData?.id as number,
          authSignupPostRequest: {
            siteId: Number(localStorage.getItem("siteId")),
            email: formData.values.email,
            name: formData.values.name,
            role: formData.values.role,
            password: formData.values.password,
            mobile: formData.values.mobile || undefined,
            exposedMobile: formData.values.exposedMobile || undefined,
          },
        },
        {
          onSuccess: () => {
            alert("사용자 정보가 수정되었습니다.");
            closeModal({});
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Flex direction="column" gap="md" w="20rem">
      <TextInput
        withAsterisk
        label="사용자아이디"
        placeholder="예: advisor"
        {...formData.getInputProps("email")}
      />
      <TextInput
        withAsterisk
        label="이름"
        placeholder="예: 홍길동"
        {...formData.getInputProps("name")}
      />
      <TextInput
        withAsterisk
        label="핸드폰 번호"
        placeholder="예: 010-9876-5432"
        {...formData.getInputProps("mobile")}
      />
      <TextInput
        withAsterisk
        label="노출 가능한 핸드폰 번호"
        placeholder="예: 010-1234-5678"
        {...formData.getInputProps("exposedMobile")}
      />
      <Select
        withAsterisk
        label="포지션"
        data={roles}
        disabled
        {...formData.getInputProps("role")}
      />
      <TextInput
        withAsterisk
        label="패스워드"
        placeholder="**********"
        {...formData.getInputProps("password")}
        type="password"
      />
      <TextInput
        withAsterisk
        label="패스워드 확인"
        placeholder="**********"
        value={passwordCheck}
        onChange={(e) => setPasswordCheck(e.currentTarget.value)}
        type="password"
        error={
          formData.values.password !== passwordCheck
            ? "패스워드가 일치하지 않습니다."
            : ""
        }
      />
      <Flex justify="flex-end" gap="md">
        <Button color="gray" onClick={() => closeModal({})}>
          취소
        </Button>
        <Button onClick={onSubmit}>사용자 정보 수정</Button>
      </Flex>
    </Flex>
  );
};

export default EditForm;
